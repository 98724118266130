namespace TotalContest {
    export var openModalOnceLoaded = '';

    export class ModalBehaviour {
        protected oldModal?: HTMLElement;
        protected modal: HTMLElement;
        protected closeButton: HTMLElement;
        protected container: HTMLElement;
        protected content: HTMLElement;
        protected previous: HTMLElement;
        protected previousPage: any;
        protected next: HTMLElement;
        protected nextPage: any;

        protected submissionList: Element[] = [];
        protected submission: number = 0;

        constructor(public contest: Contest) {

            this.init();

            this.contest.element.find('a[totalcontest-ajax-url].totalcontest-submissions-item-link').each((index, element) => {
                this.submissionList.push(element);
            });

            this.contest.element.find('a[totalcontest-ajax-url].totalcontest-submissions-item-link').off('click');

            this.contest.element.find('a[totalcontest-ajax-url].totalcontest-submissions-item-link').on('click', (event) => {
                this.submission = this.submissionList.indexOf(event.currentTarget);
                this.contest.element.css('pointer-events', 'none').fadeTo(500, 0.1);

                this.update(() => {
                    this.contest.element.css('pointer-events', 'auto').fadeTo(500, 1);

                    if (this.oldModal) {
                        this.oldModal.remove();
                    }
                });

                event.preventDefault();
            });

            if (this.oldModal && !TotalContest.openModalOnceLoaded) {
                this.oldModal.remove();
            }

            if (TotalContest.openModalOnceLoaded) {
                if (TotalContest.openModalOnceLoaded === 'next') {
                    this.contest.element.find('a[totalcontest-ajax-url].totalcontest-submissions-item-link').first().click();
                } else {
                    this.contest.element.find('a[totalcontest-ajax-url].totalcontest-submissions-item-link').last().click();
                }
            }

            TotalContest.openModalOnceLoaded = '';
        }

        protected init() {
            this.oldModal = document.getElementById(`totalcontest-modal-${this.contest.uid}`)

            this.modal = document.createElement('div');
            this.modal.classList.add('totalcontest-modal');
            this.modal.setAttribute('id', `totalcontest-modal-${this.contest.uid}`);

            let loader = document.createElement('div');
            loader.classList.add('totalcontest-modal-loader');
            loader.innerHTML = '<svg width="38" height="38" viewBox="0 0 38 38" stroke="#fff"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" /></path></g></g></svg>';

            this.modal.appendChild(loader);

            this.closeButton = document.createElement('span');
            this.closeButton.classList.add('totalcontest-modal-close');
            this.closeButton.addEventListener('click', () => {
                this.close();
            });

            this.container = document.createElement('div');
            this.container.classList.add('totalcontest-modal-container');
            this.container.style.maxWidth = this.contest.element.outerWidth() + 'px';

            this.content = document.createElement('div');
            this.content.classList.add('totalcontest-modal-content');

            this.previous = document.createElement('span');
            this.previous.innerHTML = '<svg width="24" height="24" viewBox="0 0 28 28"><path d="M20.4,4.8L17.6,2l-12,12l12,12l2.8-2.8L11.3,14L20.4,4.8z"/></svg>';
            this.previous.classList.add('totalcontest-modal-previous');
            this.previous.addEventListener('click', () => {
                this.getPrevious();
            });
            this.previousPage = this.contest.element.find('a.totalcontest-pagination-previous');

            this.next = document.createElement('span');
            this.next.classList.add('totalcontest-modal-next');
            this.next.innerHTML = '<svg width="24" height="24" viewBox="0 0 28 28"><path d="M20.4,4.8L17.6,2l-12,12l12,12l2.8-2.8L11.3,14L20.4,4.8z"/></svg>';
            this.next.addEventListener('click', () => {
                this.getNext();
            });
            this.nextPage = this.contest.element.find('a.totalcontest-pagination-next');

            this.container.appendChild(this.content)

            this.modal.appendChild(this.closeButton)
            this.modal.appendChild(this.next)
            this.modal.appendChild(this.previous)
            this.modal.appendChild(this.container);

            document.body.appendChild(this.modal);
        }

        open(content: HTMLElement) {
            this.content.innerText = '';
            document.documentElement.style.overflowY = 'hidden';
            this.content.appendChild(content);
            this.preload();

            if (!this.submissionList[this.submission + 1] && !this.nextPage.length) {
                this.next.style.display = 'none';
            }

            if (!this.submissionList[this.submission - 1] && !this.previousPage.length) {
                this.previous.style.display = 'none';
            }

            this.modal.classList.add('-totalcontest-modal-is-open')
        }

        preload() {
            let submissionContent = this.content.querySelector('.totalcontest-submission-content');

            if (submissionContent == undefined) {
                this.modal.classList.add('-totalcontest-modal-is-loaded');
                return;
            }

            let images = jQuery(submissionContent).find('img:not([src=""])');

            if (images.length == 0) {
                this.modal.classList.add('-totalcontest-modal-is-loaded');
                return;
            }

            let loadedImages = 0;

            jQuery(images).each((index, image) => {

                let img = new Image;
                img.onload = () => {
                    loadedImages++;
                    if (loadedImages === images.length) {
                        this.modal.classList.add('-totalcontest-modal-is-loaded');
                        return;
                    }
                }

                img.src = jQuery(image).attr('src');
            });

        }

        getNext() {
            if (this.submissionList[this.submission + 1]) {
                this.submission++;
                this.previous.style.display = 'flex';
                this.update();
            } else if (this.nextPage.length) {
                TotalContest.openModalOnceLoaded = 'next';
                this.modal.classList.remove('-totalcontest-modal-is-loaded');
                this.nextPage.click();
            }

            if (!this.submissionList[this.submission + 1] && !this.nextPage.length) {
                this.next.style.display = 'none';
            }
        }

        getPrevious() {
            if (this.submissionList[this.submission - 1]) {
                this.submission--;
                this.next.style.display = 'flex';
                this.update();
            } else if (this.previousPage) {
                TotalContest.openModalOnceLoaded = 'previous';
                this.modal.classList.remove('-totalcontest-modal-is-loaded');
                this.previousPage.click();
            }

            if (!this.submissionList[this.submission - 1] && !this.previousPage.length) {
                this.previous.style.display = 'none';
            }
        }

        close() {
            this.content.innerHTML = '';
            this.modal.classList.remove('-totalcontest-modal-is-open')
            document.documentElement.style.overflowY = 'initial';
            this.next.style.display = 'flex';
            this.previous.style.display = 'flex';
        }

        update(callback = null) {
            this.modal.classList.remove('-totalcontest-modal-is-loaded');

            let ajaxUrl = jQuery(this.submissionList[this.submission]).attr('totalcontest-ajax-url');

            if (ajaxUrl) {
                this.load(ajaxUrl, callback);
            }
        }

        destroy() {
            // this.modal.remove();
        }

        load(url, callback = null) {
            jQuery.ajax({
                url: url,
                type: 'GET',
                success: (response) => {
                    let $contest = jQuery(response);
                    $contest.find('link[rel="stylesheet"], style').remove();
                    this.contest.element.css({'pointer-events': 'auto', 'opacity': 1});
                    this.open($contest.get(0));

                    let contest = new TotalContest.Contest($contest, false, false, this.contest.uid);

                    if (callback) {
                        callback(contest);
                    }
                }
            });
        }
    }
}
