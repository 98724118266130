namespace TotalContest {
    import Contest = TotalContest.Contest;

    export class ScrollOverflowBehaviour {
        public scrollables;

        constructor(public contest: Contest) {
            this.scrollables = contest.element.find('[totalcontest-mobile-scrollable]');
            this.scrollables.on(
                'scroll',
                (event) => this.receiveScroll(event)
            );
            this.scrollables.each((index, scrollable) => {
                var $scrollable = jQuery(scrollable);
                $scrollable.scrollLeft($scrollable.find('.is-active').first().offset().left);
            })
        }

        destroy() {
            this.scrollables.off('scroll');
        }

        receiveScroll(event) {
            var $target = jQuery(event.target);
            var maxWidth = $target.prop('scrollWidth') - $target.prop('clientWidth');
            if (Math.round($target.scrollLeft()) >= maxWidth) {
                $target.parent().addClass('is-scroll-finished');
            } else {
                $target.parent().removeClass('is-scroll-finished');
            }
        }
    }
}