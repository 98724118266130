namespace TotalContest {
    import Contest = TotalContest.Contest;

    export class EmbedResizingBehaviour {
        public listener;

        constructor(public contest: Contest) {
            this.listener = (event) => this.receiveRequest(event);
            window.addEventListener("message", this.listener, false);
        }

        destroy() {
            window.removeEventListener("message", this.listener);
        }

        postHeight() {
            top.postMessage({totalcontest: {id: this.contest.id, action: 'resizeHeight', value: jQuery(document.body).height()}}, '*');
        }

        receiveRequest(event) {
            if (event.data.totalcontest && event.data.totalcontest.id === this.contest.id && event.data.totalcontest.action === 'requestHeight') {
                this.postHeight();
            }
        }
    }
}