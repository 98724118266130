namespace TotalContest {
    import Contest = TotalContest.Contest;

    export class UploadedFile {
        public URL = window.URL || window['webkitURL'];

        constructor(private originalFile: any) {

        }

        getDimensions(callback) {
            var image: any = document.createElement('img');
            image.onload = (event) => {
                this.originalFile.dimensions = image.width + 'x' + image.height;
                callback(this.originalFile.dimensions);
            };
            image.src = this.getObjectURL();
        }

        getDuration(callback) {
            var media: any = document.createElement(this.getType());
            media.src = this.getObjectURL();
            media.ondurationchange = () => {
                this.originalFile.duration = new Date(media['duration'] * 1024).toISOString().substr(14, 5);
                callback(this.originalFile.duration);
            };
        }

        getObjectURL() {
            return URL.createObjectURL(this.originalFile);
        }

        getPreview(callback) {
            switch (this.getType()) {
                case 'image' : {
                    this.getDimensions(() => callback(`${this.originalFile.name} - ${this.originalFile.dimensions} (${this.getSize()})`));
                    break;
                }
                case 'audio' :
                case 'video' : {
                    this.getDuration(() => callback(`${this.originalFile.name} - ${this.originalFile.duration} (${this.getSize()})`));
                    break;
                }
                default : {
                    callback(`${this.originalFile.name} - (${this.getSize()})`);
                }
            }
        }

        getSize() {
            return Utils.getFileSizeForHuman(this.originalFile.size);
        }

        getType() {
            return this.originalFile.type.split('/')[0];
        }
    }

    export class MaxLengthValidation {
        public counter;
        public maxlength = 0;

        constructor(public element) {
            this.maxlength = element.attr('maxlength');
            this.counter = jQuery(`<div style="float: right;margin-top: 0.5em;"></div>`);
            element.after(this.counter);

            element.on('change keypress keyup keydown', () => this.updateCounter());
            this.updateCounter();
        }

        updateCounter() {
            this.counter.text(window['jqValidationMessages']['left'].replace('%d', this.maxlength - this.element.val().length))
        }
    }

    export class VoteFormBehaviour {
        public fileInputsElements;
        public formElement;
        public formLoading;

        constructor(public contest: Contest) {
            this.formElement = contest.element.find('.totalcontest-form-vote');
            this.formLoading = contest.element.find('.totalcontest-form-loading');
            this.fileInputsElements = contest.element.find('.totalcontest-form-field-type-file');

            this.formElement.validate({
                submitHandler: (form, event) => {
                    this.showProgress(form, event);
                    if(! this.contest.config['behaviours']['ajax']) {
                        form.submit();
                    }
                },
                onclick: true,

                errorPlacement: function (error, element) {
                    element.siblings(".totalcontest-form-field-errors").html('');
                    error.appendTo(element.siblings(".totalcontest-form-field-errors"));
                }
            });
            this.formElement.attr('novalidate', 'novalidate');
            this.fileInputsElements.each((index, element) => {
                element = jQuery(element);
                let fileInputElement = element.find('input');
                let fileLabelElement = element.find('label');

                fileInputElement.on('change', (event) => this.handleFile(event, fileInputElement, fileLabelElement));
                fileLabelElement.data('original-text', fileLabelElement.text());
            });

            contest.element.find('[maxlength]:not([type="file"])').each(function () {
                new MaxLengthValidation(jQuery(this));
            });

            contest.element.find('[maxlength][type="file"],[minlength][type="file"]').each(function () {
                let $field = jQuery(this);
                let rules: any = {};
                let minLength = Number($field.attr('minlength') || 0);
                let maxLength = Number($field.attr('maxlength') || 0);
                if (minLength > 0) {
                    rules.minfilesize = [Utils.getFileSizeForHuman(minLength * 1024), minLength];
                }
                if (maxLength > 0) {
                    rules.maxfilesize = [Utils.getFileSizeForHuman(maxLength * 1024), maxLength];
                }
                $field.rules('add', rules);
                $field.rules('remove', 'minlength maxlength');
                $field.removeAttr('minlength');
                $field.removeAttr('maxlength');
            });

            contest.element.find('[formats][type="file"]').each(function () {
                let $field = jQuery(this);
                $field.rules('add', {
                    formats: [$field.attr('formats'), $field.attr('accept').split(',')]
                });
            });

            contest.element.find('[required-if-empty]').each(function () {
                let $field = jQuery(this);
                let $remoteField = jQuery($field.attr('required-if-empty'));
                $remoteField.on('change', function () {
                    $field.prop('required', !Boolean($remoteField.val()));
                });
            });
        }

        handleFile(event: any, input, label) {
            if (event.target.files[0]) {
                var file = new UploadedFile(event.target.files[0]);
                file.getPreview((content) => label.text(content));
            } else {
                label.text(label.data('original-text'));
            }

            input.blur();
        }

        showProgress(form, event) {
            this.formElement.find('[type="submit"]').prop('disabled', true);
            this.formLoading.addClass('active');

            jQuery('body').animate({scrollTop: this.formLoading.find('svg').offset().top - 250});
            setTimeout(() => this.contest.element.finish().fadeTo(0, 1), 1);
        }
    }
}
