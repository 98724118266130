namespace TotalContest {
    import Contest = TotalContest.Contest;

    export class SubmissionsListingBehaviour {
        public layoutTogglers;
        public submissions;

        constructor(public contest: Contest) {
            this.submissions = contest.element.find('[totalcontest-submissions]');
            this.layoutTogglers = contest.element.find('[totalcontest-submissions-toggle-layout]');
            this.layoutTogglers.on(
                'click',
                (event) => this.toggleLayout(
                    event.currentTarget.getAttribute('totalcontest-submissions-toggle-layout')
                )
            );
        }

        toggleLayout(layout) {
            this.layoutTogglers.removeClass('totalcontest-submissions-toolbar-active');
            this.layoutTogglers.filter(`[totalcontest-submissions-toggle-layout="${layout}"]`).addClass('totalcontest-submissions-toolbar-active');
            this.submissions.removeClass('totalcontest-submissions-items-layout-grid totalcontest-submissions-items-layout-list');
            this.submissions.addClass(`totalcontest-submissions-items-layout-${layout}`);
        }
    }
}