namespace TotalContest {
    import Contest = TotalContest.Contest;

    export class AjaxBehaviour {
        constructor(public contest: Contest) {
            this.contest.on('state', (event, state) => {
                if (state && state.ajax) {
                    this.load(state.ajax);
                }
            });

            contest.element.find('a[totalcontest-ajax-url]').on('click', (event) => {
                let ajaxUrl = jQuery(event.currentTarget).attr('totalcontest-ajax-url');
                if (ajaxUrl) {
                    window.history.pushState({ajax: ajaxUrl}, null, event.currentTarget.href);
                    this.load(ajaxUrl);
                }
                event.preventDefault();
            });

            contest.element.find('option[totalcontest-ajax-url]').closest('select').on('change', (event) => {
                let selected = jQuery(event.currentTarget).find('option:selected');
                let ajaxUrl = selected.attr('totalcontest-ajax-url');
                if (ajaxUrl) {
                    window.history.pushState({ajax: ajaxUrl}, null, selected.val());
                    this.load(ajaxUrl);
                }
                event.preventDefault();
            });

            contest.element.find('form').on('submit', (event) => {
                event.preventDefault();
                if (!jQuery.fn.valid || jQuery(event.currentTarget).valid()) {
                    this.load(decodeURI(event.currentTarget.getAttribute('action') || contest.config.ajaxEndpoint), new FormData(event.currentTarget), event.currentTarget.method || 'post');
                }
            });
        }

        load(url, data = {}, method = 'GET') {
            method = method ? method.toLowerCase() : method;

            this.contest.element.css('pointer-events', 'none').fadeTo(500, 0.1, () => {
                if (method.toLowerCase() == 'get' && data instanceof FormData) {
                    data = Object.fromEntries(data.entries());
                }
                jQuery.ajax({
                    url: url,
                    data: data,
                    processData: method != 'post',
                    contentType: false,
                    type: method,
                    success: (response) => {
                        let $contest = jQuery(response).hide();
                        this.contest.element.replaceWith($contest);
                        $contest.fadeIn();

                        new TotalContest.Contest($contest, true, this.contest.config['behaviours']['async'], this.contest.uid);
                    }
                });
            });
        }

    }
}
